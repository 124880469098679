



















































































































































































import Vue from 'vue'
import Layout from "@/router/layouts/main.vue";
import { mapActions, mapGetters } from "vuex";
import { successMessage } from "@/utils/messages-handlers";
export default Vue.extend({
    components: {
        Layout
    },
    data() {
        return {
            orig_flag: require("@/assets/images/flags/en.png"),
            orig_lang: "English",
            trans_flag: require("@/assets/images/sk-flag.svg"),
            trans_lang: "Slovakia",
            languages: [
                {
                    flag: require("@/assets/images/flags/en.png"),
                    language: "en",
                    title: "English",
                },
                {
                    flag: require("@/assets/images/sk-flag.svg"),
                    language: "sk",
                    title: "Slovakia"
                }
            ],

            productBasic: {},
            productPremium: {},
            serviceDescriptions: {},
            additionalServicesMonth: {},
            additionalServicesYear: {},
            taxPricePeriod: {},
                   }
    },

   async created() {
      await this.setProductsForEdit();
      await this.setSubscriptionDescrtiptionsForEdit();
      await this.setTaxPricePeriodTranslationForEdit();
   },

  computed: {
      ...mapGetters("azProductsModule", {
        getProductBasic: "GET_PRODUCT_BASIC_MONTH",
        getProductPremium: "GET_PRODUCT_PREMIUM_MONTH",
        getAdditionalServicesMonth: "GET_ADDITIONAL_SERVICES_MONTH",
        getAdditionalServicesYear: "GET_ADDITIONAL_SERVICES_YEAR"
      }),
      ...mapGetters("azSubscriptionDescriptionsModule", {
        getSubscriptionDescriptions: "GET_ACTIVE_SUBSCRIPTION_DESCRIPTIONS"
      }),
    ...mapGetters("azTaxPricePeriodTranslationModule", {
      getTaxPricePeriodTranslation: "GET_TAX_PRICE_PERIOD_TRANSLATION"
    })
  },
  methods: {
    ...mapActions("azProductsModule", {
      setProducts: "FETCH_PRODUCTS",
      updateMainPackageName: "UPDATE_MAIN_PACKAGE_NAME",
      updateAdditionalServiceName: "UPDATE_ADITIONAL_SERVICE_NAME"
    }),
    ...mapActions("azSubscriptionDescriptionsModule", {
      setSubscriptionDescriptions: "FETCH_SUBSCRIPTION_DESCRIPTIONS",
      updateSubscriptionDescriptionTranslation: "UPDATE_SUBSCRIPTION_DESCRIPTION_TRANSLATION",

    }),
    ...mapActions("azTaxPricePeriodTranslationModule", {
      setTaxPricePeriodTranslation: "FETCH_TAX_PRICE_PERIOD_TRANSLATION",
      updateTaxPricePeriodTranslation: "UPDATE_TAX_PRICE_PERIOD_TRANSLATION"
    }),
    async setProductsForEdit(){
      await this.setProducts();
      this.productBasic = {
        ...this.getProductBasic[0]
      }
      this.productPremium = {
        ...this.getProductPremium[0]
      },
      this.additionalServicesMonth = this.getAdditionalServicesMonth
      this.additionalServicesYear = this.getAdditionalServicesYear
    },
    async setSubscriptionDescrtiptionsForEdit(){
      await this.setSubscriptionDescriptions();
      this.serviceDescriptions = this.getSubscriptionDescriptions
    },
    async setTaxPricePeriodTranslationForEdit(){
      await this.setTaxPricePeriodTranslation()
      this.taxPricePeriod = {
        ...this.getTaxPricePeriodTranslation[0]
      }
    },
    async updateTranslations(){
      const basicPayload = {
        "songorooPackageType": 1,
        "productName": this.productBasic.productName,
        "productNameSk": this.productBasic.productNameSk,
        "isProductNameTranslationCompleted": this.productBasic.isProductNameTranslationCompleted
      };
      const premiumPayload = {
        "songorooPackageType": 2,
        "productName": this.productPremium.productName,
        "productNameSk": this.productPremium.productNameSk,
        "isProductNameTranslationCompleted": this.productBasic.isProductNameTranslationCompleted
      };
      await this.updateMainPackageName(basicPayload)
      await this.updateMainPackageName(premiumPayload)

      for (const additionalService of this.additionalServicesMonth) {
        const productId = additionalService.id
        const updatedServicePayload = {
          "productName": additionalService.productName ? additionalService.productName : '',
          "productNameSk": additionalService.productNameSk ? additionalService.productNameSk : '',
          "isProductNameTranslationCompleted": additionalService.isProductNameTranslationCompleted
        };
        await this.updateAdditionalServiceName({productId: productId, payload: updatedServicePayload})
      }

      for (const additionalService of this.additionalServicesYear) {
        const productId = additionalService.id
        const updatedServicePayload = {
          "productName": additionalService.productName,
          "productNameSk": additionalService.productNameSk,
          "isProductNameTranslationCompleted": additionalService.isProductNameTranslationCompleted
        };
        await this.updateAdditionalServiceName({productId: productId, payload: updatedServicePayload})
      }

      for(const serviceDescription of this.serviceDescriptions){
        const descriptionId = serviceDescription.id
        const descriptionPayload = {
          "descriptionEn": serviceDescription.descriptionEn,
          "descriptionSk": serviceDescription.descriptionSk,
          "isTranslationCompleted": serviceDescription.isTranslationCompleted,
        };
        await this.updateSubscriptionDescriptionTranslation({descriptionId: descriptionId, payload: descriptionPayload})
      }
      const taxPricePeriodTranslations = {
        "priceAndPeriodMonthSk": this.taxPricePeriod.priceAndPeriodMonthSk,
        "priceAndPeriodMonthEn": this.taxPricePeriod.priceAndPeriodMonthEn,
        "isPriceAndPeriodMonthTranslationCompleted": this.taxPricePeriod.isPriceAndPeriodMonthTranslationCompleted,
        "priceAndPeriodYearSk": this.taxPricePeriod.priceAndPeriodYearSk,
        "priceAndPeriodYearEn": this.taxPricePeriod.priceAndPeriodYearEn,
        "isPriceAndPeriodYearTranslationCompleted": this.taxPricePeriod.isPriceAndPeriodYearTranslationCompleted,
        "taxInformationSk": this.taxPricePeriod.taxInformationSk,
        "taxInformationEn": this.taxPricePeriod.taxInformationEn,
        "isTaxInformationCompleted": this.taxPricePeriod.isTaxInformationCompleted
      }
      await this.updateTaxPricePeriodTranslation(taxPricePeriodTranslations)


      successMessage("Translations updated", 3000)
      await this.$router.push('/subscription-plans')
    },
    setOriginalLanguage(title, flag) {
      this.orig_lang = title;
      this.orig_flag = flag;
    },
    setTranslationLanguage(title, flag) {
      this.trans_lang = title;
      this.trans_flag = flag;
    }
  },
})
